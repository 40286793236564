<template>
  <div class="card" style="margin-bottom:30px;" :ref="'scheduledFlight_' + index">
    <header class="card-header">
      <p class="card-header-title">
        <span>Route {{index + 1}}</span>
      </p>
    </header>
    <div class="card-content">
      <div class="columns">
        <div class="column">
          <b-field label="ETD">
            <b-input
                v-cleave="masks.time"
                name="etd"
                v-model="scheduledFlight.etd"
                v-validate="{ required: false }"
                @keyup.native.prevent="nextOnComplete($event)">
            </b-input>
          </b-field>
          <p class="help is-danger">{{ errors.first('etd') }}</p>
        </div>
        <div class="column">
          <b-field label="ETA">
            <b-input
                v-cleave="masks.time"
                name="eta"
                v-model="scheduledFlight.eta"
                v-validate="{ required: false }"
                @keyup.native.prevent="nextOnComplete($event)">
            </b-input>
          </b-field>
          <p class="help is-danger">{{ errors.first('eta') }}</p>
        </div>

      </div>
      <div class="columns" style="margin-bottom:30px;">
        <div class="column is-2">
          <b-tooltip
              label="Route ID is required"
              :active="!validations.routeIsValid"
              position="is-bottom"
          >
            <b-field label="Route">
              <b-field :type="{'is-danger' : !validations.routeIsValid}">
                <RouteSelect
                    v-model="scheduledFlight.route_id"
                    :inicial="scheduledFlight.route_id"
                    ref="routeSelect"
                    v-on:selectedOption="setRoute"
                >
                </RouteSelect>
                <p class="control" @click="onRouteSearchModal">
                  <span class="button"><i class="fa fa-search"></i></span>
                </p>
              </b-field>
            </b-field>
          </b-tooltip>
        </div>
        <div class="column is-2">
          <b-field label="Flight Level"
                   :type="{'is-danger' : !validations.flightLevelIsValid}"
                   style="min-width:100%;">
            <b-input
                v-model="flight_level_proxy"
                step="5"
                ref="flightLevelElement"
                expanded
                style="min-width:100%;"
            >
            </b-input>
          </b-field>
        </div>
        <div class="column is-8">
          <RouteNavDisplay :route="scheduledFlight.route" />
        </div>
      </div>
      <div class="card" v-if="scheduledFlight.route && scheduledFlight.route.id"
           style="margin-bottom:30px;">
        <header class="card-header" style="background-color: transparent;">
          <p class="card-header-title">
            <span>Alternate Routes</span>
          </p>
        </header>
        <div class="card-content">
          <div class="columns" v-for="(airport, index) in scheduledFlight.alternateAirports"
               :key="index" style="margin-bottom:30px;">
            <div class="column is-11" style="margin-left:10px;">
              <RouteForScheduledAlternateAirport :scheduledFlight="scheduledFlight" :airport="airport" :validations="validations"
                                                 :index="index"></RouteForScheduledAlternateAirport>
            </div>
            <div class="column is-1" style="margin-top:30px;">
              <button v-if="index != 0" class="button is-danger" @click="removeAlternateAirport(index)">
                Remove
              </button>
            </div>
          </div>
          <div class="columns">
            <div class="column is-2">
              <button v-if="scheduledFlight.alternateAirports.length < 2"
                      class="button is-primary is-small is-rounded" @click="addAlternateAirport()">
                <i class="fa fa-plus"></i>&nbsp;Add Alternate Route
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {EventBus} from "@/event-bus";
import RouteSearchModal from "@/components/plans/RouteSearchModal";
import RouteSelect from "@/components/RouteSelect";
import RouteForScheduledAlternateAirport from "@/components/flight-schedule/RouteForScheduledAlternateAirport";
import {mask} from "vue-the-mask";
import Cleave from 'cleave.js'
import RouteNavDisplay from "@/components/routes/RouteNavDisplay";

const cleave = {
  name: 'cleave',
  bind(el, binding) {
    const input = el.querySelector('input')
    input._vCleave = new Cleave(input, binding.value)
  },
  unbind(el) {
    const input = el.querySelector('input')
    input._vCleave.destroy()
  }
}

export default {
  name: "FlightScheduleRoute",
  components: {RouteNavDisplay, RouteForScheduledAlternateAirport, RouteSelect},
  directives: {cleave, mask},
  props: {
    scheduledFlight: Object,
    index: Number,
  },
  data() {
    return {
      masks: {
        time: {
          time: true,
          timePattern: ['h', 'm']
        }
      },
    }
  },
  computed: {
    flight_level_proxy: {
      get () {
        return this.scheduledFlight.flight_level;
      },
      set (value) {
        this.scheduledFlight.setFlightLevel(value);
      }
    },
    alternateAirportsIsValid: function () {
      return true;
    },
    validations: function () {
      return {
        routeIsValid: this.scheduledFlight.routeIsValid,
        flightLevelIsValid: this.scheduledFlight.flightLevelIsValid,
        alternateAirports: this.alternateAirportsIsValid
      }
    },
    isValid: function() {
      return !(JSON.stringify(this.validations).includes(`false`));
    }
  },
  methods: {
    addAlternateAirport(params = {route: {}, route_id: null, flight_level: null}) {
      if (this.scheduledFlight.alternateAirports && this.scheduledFlight.alternateAirports.length > 1) {
        return;
      }
      this.scheduledFlight.alternateAirports.push(params);
      this.$forceUpdate();
    },
    removeAlternateAirport(index) {
      this.$delete(this.scheduledFlight.alternateAirports, index);
      this.$delete(this.validations.alternateAirports, index);
      EventBus.$emit('alternateAirportRemoved');
      this.$forceUpdate();
    },
    nextOnComplete(e) {
      if (e.target.value.length === 5)
        this.focusNext(e.target)
    },
    focusNext(elem) {
      const currentIndex = Array.from(elem.form.elements).indexOf(elem);
      elem.form.elements.item(
          currentIndex < elem.form.elements.length - 1 ?
              currentIndex + 1 :
              0
      ).focus();
    },
    onRouteSearchModal() {
      this.$buefy.modal.open({
        parent: this,
        component: RouteSearchModal,
        props: {},
        width: 1200,
        canCancel: false,
        events: {
          onRouteSelected: (route) => {
            this.setRouteFromAdvanced(route);
          }
        }
      })
    },
    setRoute: function (route) {
      this.scheduledFlight.route_id = route.id;
      this.scheduledFlight.route = route
    },
    setRouteFromAdvanced: function (route) {
      this.scheduledFlight.route_id = route.id;
      this.scheduledFlight.route = route;
      this.$refs['routeSelect'].search = this.scheduledFlight.route_id;
    },
  },
}
</script>

<style scoped>

</style>