export default class PaginationFilter
{
    hasValue(value) {
        return Object.is(value, null) == false &&
               Object.is(value, undefined) == false &&
               Object.is(value, '') == false;
    }
    isEmpty() {
        return Object.values(this).every(value => value == null);
        // return Object.values(this).every(value => !this.hasValue(value));
    }

    reset() {
        for (var key in this) {
            this[key] = null;
        }
    }
    // checkFilters () {
    //     for (var key in this.filtros) {
    //         if (this.filtros[key] !== null && this.filtros[key] != "")
    //             return true
    //     }
    //     return false;
    // }
}