import ApiService from "./api.service"

export default class ScheduledFlightsService extends ApiService
{
    path = 'scheduled-flights';

    paginate(params) {
        return this.http.get(`${this.url}/paginate`, { params: params } );
    }

    fleets() {
        return this.http.get(`${this.url}/fleets`);
    }
}