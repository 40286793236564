<template>
  <section id="FlightScheduleCreatorSection">
    <vue-headful title="FLPN Aviasolutions - Define Flight Schedule"></vue-headful>
    <h1 class="title">{{ pageTitle }}</h1>
    <div class="columns is-centered">
      <div class="column is-12">
        <div class="card">
          <div class="card-content">
            <form action="#" autocomplete="off" v-on:submit.prevent="save">
              <div class="card" style="margin-bottom:30px;">
                <header class="card-header">
                  <p class="card-header-title">
                    <span>Schedule</span>
                  </p>
                </header>
                <div class="card-content">
                  <b-field grouped>
                    <b-field label="Flight Number" expanded>
                      <b-input v-model="scheduledDefiner.flight_number" required></b-input>
                    </b-field>

                    <b-field label="Fleet" v-if="$auth.check(['admin', 'admin-client'])" expanded>
                      <b-select v-model="scheduledDefiner.fleet_id" placeholder="Select a Fleet" expanded required>
                        <optgroup v-for="(fleets, group) in fleetGroups" :label="group">
                          <option
                              v-for="fleet in fleets"
                              :value="fleet.id"
                              :key="fleet.id">
                            {{ fleet.name }}
                          </option>
                        </optgroup>
                      </b-select>
                    </b-field>
                    <b-field label="Begining">
                      <datepicker placeholder="Select begining date" :config="{ dateFormat: 'm/d/Y', static: true }"
                                  v-model="scheduledDefiner.begining" :input-class="['is-expanded']"></datepicker>
                    </b-field>
                    <b-field label="End">
                      <datepicker placeholder="Select ending date" :config="{ dateFormat: 'm/d/Y', static: true }"
                                  v-model="scheduledDefiner.end"></datepicker>
                    </b-field>
                  </b-field>
                  <div class="columns is-centered">
                    <div class="column is-1">
                      <b-field label="MONDAY">
                        <b-switch v-model="scheduledDefiner.monday" type="is-success">
                          {{ scheduledDefiner.monday ? 'YES' : 'NO' }}
                        </b-switch>
                      </b-field>
                    </div>
                    <div class="column is-1">
                      <b-field label="TUESDAY">
                        <b-switch v-model="scheduledDefiner.tuesday" type="is-success">
                          {{ scheduledDefiner.tuesday ? 'YES' : 'NO' }}
                        </b-switch>
                      </b-field>
                    </div>
                    <div class="column is-1">
                      <b-field label="WEDNESDAY">
                        <b-switch v-model="scheduledDefiner.wednesday" type="is-success">
                          {{ scheduledDefiner.wednesday ? 'YES' : 'NO' }}
                        </b-switch>
                      </b-field>
                    </div>
                    <div class="column is-1">
                      <b-field label="THURSDAY">
                        <b-switch v-model="scheduledDefiner.thursday" type="is-success">
                          {{ scheduledDefiner.thursday ? 'YES' : 'NO' }}
                        </b-switch>
                      </b-field>
                    </div>
                    <div class="column is-1">
                      <b-field label="FRIDAY">
                        <b-switch v-model="scheduledDefiner.friday" type="is-success">
                          {{ scheduledDefiner.friday ? 'YES' : 'NO' }}
                        </b-switch>
                      </b-field>
                    </div>
                    <div class="column is-1">
                      <b-field label="SATURDAY">
                        <b-switch v-model="scheduledDefiner.saturday" type="is-success">
                          {{ scheduledDefiner.saturday ? 'YES' : 'NO' }}
                        </b-switch>
                      </b-field>
                    </div>
                    <div class="column is-1">
                      <b-field label="SUNDAY">
                        <b-switch v-model="scheduledDefiner.sunday" type="is-success">
                          {{ scheduledDefiner.sunday ? 'YES' : 'NO' }}
                        </b-switch>
                      </b-field>
                    </div>
                  </div>
                </div>
              </div>

              <div class="is-right">
                <button type="button" @click="addScheduledFlight()" class="button is-info is-small is-rounded"
                        style="margin-bottom:10px;">Add Route
                </button>
              </div>

              <FlightScheduleRoute v-for="(scheduledFlight, index) in scheduledFlights" :key="index"
                                   :scheduled-flight="scheduledFlight" :index="index"/>

              <div class="columns">
                <div class="column control" v-if="scheduledDefiner.id">
                  <button type="button" class="button is-danger">
                    <i class="fa fa-minus-circle"></i>&nbsp;
                    <span class="has-text-weight-bold is-uppercase"
                          v-on:click="onDeleteSchedule()">Delete Schedule</span>
                  </button>
                </div>
                <div class="column field is-grouped is-grouped-right">
                  <p class="control">
                    <router-link :to="'/flight-schedule'" class="button is-light">
                      <b-icon pack="fas" icon="long-arrow-alt-left"></b-icon>
                      <span class="has-text-weight-bold is-uppercase">Back to Flight Schedule</span>
                    </router-link>
                  </p>
                  <p class="control">
                    <!--                  <button type="submit" :disabled="!scheduledFlight.isValid" class="button is-primary">-->
                    <button type="submit" class="button is-primary" :disabled="!isValid">
                      <b-icon pack="far" icon="save"></b-icon>
                      <span class="has-text-weight-bold is-uppercase">Save</span>
                    </button>
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ScheduledFlightsService from "../../services/scheduled-flights.service.js";
import FleetsService from "../../services/fleets.service";
import RouteSelect from "../../components/RouteSelect";
import RouteSearchModal from "../../components/plans/RouteSearchModal";
import RouteForScheduledAlternateAirport from "./RouteForScheduledAlternateAirport";
import Datepicker from 'vue-bulma-datepicker'
import { mapGetters, mapState, mapActions } from "vuex";

import FlightScheduleRoute from "@/components/flight-schedule/FlightScheduleRoute";

export default {
  data() {
    return {
      pageTitle: 'Define Flight Schedule',
      fleetsService: new FleetsService(),
      scheduledFlightsService: new ScheduledFlightsService(),
      scheduledDefiner: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
        flight_number: null,
        fleet_id: null,
        begining: null,
        end: null,
        scheduledFlights: []
      },
      fleetGroups: [],
      loaded: false,

    };
  },
  mounted: function () {
    this.resetScheduledFlights();
    this.fleetsService.selectors().then(
        ({data}) => {
          this.fleetGroups = data.fleet_groups;
        }
    );
    if (this.$route.params.id) {
      const loadingComponent = this.$buefy.loading.open({container: null})
      this.scheduledFlightsService.findOne(this.$route.params.id).then(
          ({data}) => {
            Object.assign(this.scheduledDefiner, data.data);
            this.addScheduledFlight(data.data.scheduledFlights[0]);
            // this.$forceUpdate();
            loadingComponent.close();
          },
          () => {
            loadingComponent.close();
            this.$buefy.toast.open({
              message: 'Flight Schedule not found.',
              type: 'is-danger'
            });
          }
      );
    } else {
      this.addScheduledFlight()
    }
  },
  components: {
    FlightScheduleRoute,
    RouteSelect,
    RouteSearchModal,
    RouteForScheduledAlternateAirport,
    Datepicker
  },
  computed: {
    ...mapState({
      scheduledFlights: state => state.scheduledFlights.scheduledFlights
    }),
    ...mapGetters(['allInvalidScheduledFlights']),
    formErrors() {
      const errors = this.$validator.errors;
      return errors;
    },
    isValid() {
      if (this.scheduledFlights.length === 0) {
        console.log('no scheduled flights');
        return false;
      }
      if(this.allInvalidScheduledFlights.length > 0) {
        console.log('almost one scheduled flight found invalid')
        return false;
      }
      if(!this.scheduledDefiner.flight_number) {
        return false;
      }
      if(!this.scheduledDefiner.fleet_id) {
        return false;
      }
      if(!this.scheduledDefiner.begining || !this.scheduledDefiner.end) {
        return false;
      }
      if(this.getDays().length === 0) {
        return false;
      }
      return true;
    },
  },
  methods: {
    ...mapActions(["addScheduledFlight", "resetScheduledFlights"]),
    getFlightLevelTooltip() {
      return 'A flight level between 5 and 1000 is required';
    },
    save: function () {
      const loadingComponent = this.$buefy.loading.open({container: null})
      const input = {... this.scheduledDefiner}
      input.scheduledFlights = this.scheduledFlights;
      this.scheduledFlightsService.create(input).then(
          ({data}) => {
            loadingComponent.close();
            this.$buefy.toast.open({
              message: data.notification,
              type: 'is-success'
            });
            this.$router.push(`/flight-schedule/`);

          },
          (failed) => {
            loadingComponent.close();
            const messages = failed.statusText
            this.$buefy.toast.open({
              duration: 5000,
              message: messages,
              type: 'is-danger'
            });
          }
      );
    },
    onDeleteSchedule() {
      const scheduledDefiner = this.scheduledDefiner;
      let days = this.getDays().join(', ');
      this.$buefy.dialog.confirm({
        title: "Deleting Scheduled Flight",
        message: `This will <b>delete</b> the schedule that<br><b>begins on</b>: ${this.scheduledDefiner.begining}<br>and <b>ends on</b>: ${this.scheduledDefiner.end}</br>(${days})<br>from the database, <b>are you sure?</b>`,
        cancelText: "Cancel",
        confirmText: "Yes, delete",
        type: "is-danger",
        size: "is-medium",
        // width: 600,
        hasIcon: true,
        onConfirm: () => {
          const loadingComponent = this.$buefy.loading.open({container: null})
          this.scheduledFlightsService.delete(scheduledDefiner.id).then(
              ({data}) => {
                loadingComponent.close();
                this.$router.push('/flight-schedule');
                this.$buefy.toast.open({
                  message: data.notificacion,
                  type: "is-success"
                });
              },
              () => {
                loadingComponent.close();
                this.$router.push('/flight-schedule');
              }
          );
        }
      });
    },
    getDays() {
      let days = []
      if (this.scheduledDefiner.monday) {
        days.push('mon');
      }
      if (this.scheduledDefiner.tuesday) {
        days.push('tue');
      }
      if (this.scheduledDefiner.wednesday) {
        days.push('wed');
      }
      if (this.scheduledDefiner.thursday) {
        days.push('thu');
      }
      if (this.scheduledDefiner.friday) {
        days.push('fri');
      }
      if (this.scheduledDefiner.saturday) {
        days.push('sat');
      }
      if (this.scheduledDefiner.sunday) {
        days.push('sun');
      }
      return days;
    },
  }
};
</script>
<style>
#FlightScheduleCreatorSection .dropdown-menu {
  opacity: 0;
}

#FlightScheduleCreatorSection .level-item .title {
  font-size: 1.5rem;
}

#FlightScheduleCreatorSection .help {
  display: none;
}

#FlightScheduleCreatorSection .card-header {
  background-color: lightgrey;
}
</style>