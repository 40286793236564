<template>
  <section id="RouteForScheduledAlternateAirportSection">
    <div class="columns">
      <div class="column is-2">
        <b-field :label="'Route ' + (index + 1)">
          <b-field :type="{'is-danger' : !routeIsValid}">
            <RouteSelect
              v-model="airport.route_id"
              :inicial="airport.route_id"
              :ref="'routeSelect'+index"
              v-on:selectedOption="setRoute($event)"
            >
            </RouteSelect>
            <p class="control" @click="onRouteSearchModal()">
              <span class="button"><i class="fa fa-search"></i></span>
            </p>
          </b-field>
        </b-field>
      </div>
      <div class="column is-1" style="margin-left:20px;">
        <b-field label="Flight Level">
          <b-tooltip
            :label="getFlightLevelTooltip()"
            :active="!flightLevelIsValid"
            position="is-bottom"
          >
            <b-field :type="{'is-danger' : !flightLevelIsValid}">
              <b-input placeholder=""
                  type="number"
                  v-model="flight_level_proxy"
                  step="10"
                  expanded
              >
              </b-input>
            </b-field>
          </b-tooltip>
        </b-field>
      </div>
      <div class="column is-9" style="padding:0px;margin-top:1.5rem;">
        <RouteNavDisplay :route="airport.route" />
      </div>
    </div>
  </section>
</template>

<script>

import RouteSelect from "../RouteSelect";
import RouteSearchModal from "../plans/RouteSearchModal";
import { EventBus } from '../../event-bus.js';
import RouteNavDisplay from "@/components/routes/RouteNavDisplay";

export default {
  name: "RouteForScheduledAlternateAirport",
  props: ['scheduledFlight', 'airport', 'index'],
  data() {
    return {
      // alternateAirports: this.scheduledFlight.flightTab.alternateAirports
    };
  },
  computed: {
    flight_level_proxy : {
      get () {
        return this.airport.flight_level;
      },
      set (value) {
        this.airport.flight_level = parseInt(value);
      }
    },
    routeIsValid: function() {
      if(this.airport.route && this.airport.route.departure_icao === this.scheduledFlight.route.destination_icao) {
        return true;
      }
      return false;
    },
    flightLevelIsValid: function() {
      if(this.airport.flight_level > 0 && this.airport.flight_level <= 1000) {
        return true;
      }

      return false;
    },
    isValid: function() {
      return this.routeIsValid && this.flightLevelIsValid;
    }
  },
  methods: {
    getFlightLevelTooltip() {
      return 'A flight level between ' +
             0 +
             ' and ' +
             1000 +
             ' is required';
    },
    setRoute: function(route) {
      this.airport.route_id = route.id;
      this.airport.route = route
      EventBus.$emit("alternateAirportRouteSet", route);
    },
    setRouteFromAdvanced: function(route) {
      this.airport.route.route_id = route.id;
      this.airport.route = route
      this.$refs['routeSelect'+ this.index].search = route.id;
      EventBus.$emit("alternateAirportRouteSet", route);

    },
    onRouteSearchModal() {
        this.$buefy.modal.open({
            parent: this,
            component: RouteSearchModal,
            props: {
              forcedDepartureIcao: this.scheduledFlight.route.destination.icao
            },
            width: 1200,
            canCancel: false,
            events: {
              onRouteSelected: (route) => {
                this.setRouteFromAdvanced(route);
              }
            }
        })
    }
  },
  mounted: function() {
  },
  components: {
    RouteNavDisplay,
    RouteSelect,
    RouteSearchModal
  }
};
</script>
<style>
  #RouteForScheduledAlternateAirportSection .column {
    margin-top:0px;
    margin-bottom:0px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left:1px;
    padding-right: 1px;
  }
  #RouteForScheduledAlternateAirportSection .dropdown-menu {
    opacity: 0;
  }
  #RouteForScheduledAlternateAirportSection .level-item .heading{
    margin-bottom:0px;
  }
  #RouteForScheduledAlternateAirportSection .level-item .title {
    font-size: 1.5rem;
  }
  #RouteForScheduledAlternateAirportSection .help {
    display: none;
  }
  #RouteForScheduledAlternateAirportSection .column .label {
    min-width: 100px;
  }
</style>
